
const utils = require('../helpers/utils');

const JournalController = {
  selectors: {
  },

  init() {
  },

  setListeners() {

  }
};

module.exports = JournalController;
