
const Utils = {
  // Utils functions
  isOverflown(el) {
      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

      if (isIE11) {
        return false;
      } else {
        return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
      }
  },

  isKeyPressed(ev, key) {
    let isPressed;
    (ev.keyCode === key) ? isPressed = true : isPressed = false;
    return isPressed;
  }

};

module.exports = Utils;
