
const utils = require('../helpers/utils');

const ItemController = {
  selectors: {
      itemEl: 'article .topic-container-js'
  },

  init() {
    this.checkAllTopics();
  },

  setListeners() {

  },

  checkAllTopics() {

    function checkTopicsContainerHeight(el, flag = false) {
      if (utils.isOverflown(el)) {

        try {
           $(el).children('li').eq(0).remove();
        } catch (error) {
           console.error(error);
        }

        if (flag) {
          $(el).append('<li><a>...</a></li>');
        }
        checkTopicsContainerHeight(el);
      }
    }

    $(this.selectors.itemEl).each(function() {
      checkTopicsContainerHeight(this, true);
    });
  },
};

module.exports = ItemController;
