const TrainingsController = {

  selectors: {
    questionContainer: '.question-container-js',
    questionInput: '.question-container-js input',
    questionResponse: '.question-response-js',
    trainingContainer: '.training-js',
    radioDescription: '.radio__description',
    correctButton: '.correct-btn-js',
    returnButton: '.return-btn-js',
    questionInput: '.question-input-js'
  },

  init(ev) {
    this.setListeners(ev);
  },

  setListeners(ev) {
    $(this.selectors.questionInput).on('change', ev => this.checkAnswer(ev));
    $(this.selectors.correctButton).on('click', ev => this.checkAnswers(ev));
  },

  checkAnswer(ev) {
    let type = $(this.selectors.trainingContainer).data('type');

    if (type == 1) {
      // ES UNA LECCIÓN - (se muestran las respuestas al hacer click)
      let elem = $(ev.currentTarget).closest(this.selectors.questionContainer);
      let res = $(ev.currentTarget).parent().data('res');
      let des = $(ev.currentTarget).parent().data('des');

      if (res == 1) {
          // Respuesta correcta
          this.showAnswer(ev.currentTarget, elem, true, des);
      } else {
          // Respuesta incorrecta
          this.showAnswer(ev.currentTarget, elem, false, des);
      }
    }
  },

  checkAnswers(ev) {
    let type = $(this.selectors.trainingContainer).data('type');

    $(this.selectors.returnButton).removeClass('d-none');
    $(this.selectors.questionInput).attr('disabled', true);

    $(ev.currentTarget).remove();

    // ES UNA PRACTICA - (se muestran las respuestas al apretar el botón)
    $(this.selectors.questionInput + ':checked').each((i, el) => {
      let elem = $(el).closest(this.selectors.questionContainer);
      let res = $(el).parent().data('res');
      let des = $(el).parent().data('des');

      if (res == 1) {
          // Respuesta correcta
          this.showAnswer(el, elem, true, des);
      } else {
          // Respuesta incorrecta
          this.showAnswer(el, elem, false, des);
      }
    });

    ev.preventDefault();
    ev.stopPropagation();
  },

  showAnswer(target, container, correct, description) {
    if (correct) {
      // Muestra la respuesta correcta
      $(target).next().children('label').removeClass('radio__description--danger').addClass('radio__description--success');

      $(container).children('.question-response-js').empty().removeClass('--danger').addClass('--success').append('<span class="material-icons-outlined icon">done</span> <span class="description">' + description + '</span>');

    } else {
      // Muestra la respuesta incorrecta y pinta la correcta
      $(target).next().children('label').removeClass('radio__description--success').addClass('radio__description--danger');
      $(container).children('.question-response-js').empty().removeClass('--success').addClass('--danger').append('<span class="material-icons-outlined icon">close</span> <span class="description">' + description + '</span>');
      $(container).children("[data-res='1'] > input").addClass('correct-answer');
    }
  }
};

export default TrainingsController;
// module.exports = TrainingsController;
