
const FiltersController = {

  elfilterTopics: {
      selector: '#filter_topic',
      element: null,
      drag: false,
      pos: null
  },

  init() {
    this.elfilterTopics.element = $(this.elfilterTopics.selector);
    this.setListeners();
  },

  setListeners() {
    $(this.elfilterTopics.selector)
      .on('mousedown', ev => this.topicsDownHandler(ev))
      .on('mousemove', ev => this.topicsMoveHandler(ev))
      .on('mouseup', ev => this.topicsMoveStopHandler(ev))
      .on('mouseout', ev => this.topicsMoveStopHandler(ev));
  },

  topicsDownHandler(ev) {
    let el = this.elfilterTopics.element;

    this.elfilterTopics.drag = true;

    this.elfilterTopics.pos = {
      left: $(el).scrollLeft(),
      top: $(el).scrollTop(),
      x: ev.clientX,
      y: ev.clientY
    }
  },

  topicsMoveHandler(ev) {
    if (this.elfilterTopics.drag) {
      let el = this.elfilterTopics.element;
      let pos = this.elfilterTopics.pos;

      // Hasta donde se ha movido
      const dx = ev.clientX - pos.x;
      const dy = ev.clientY - pos.y;

      // Movemos el elemento
      $(el).scrollTop(pos.top - dy);
      $(el).scrollLeft(pos.left - dx);
    }
  },

  topicsMoveStopHandler(ev) {
    this.elfilterTopics.drag = false;
  }
};

module.exports = FiltersController;