
const WebinarController = {
  selectors: {

  },

  init() {
    this.setListeners();
  },

  setListeners() {

  }
};

module.exports = WebinarController;
