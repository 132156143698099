
// Page Controllers
const HomeController = require('./objects/homeController');
const ItemController = require('./objects/itemController');
const JournalController = require('./objects/journalController');
const FiltersController = require('./objects/filtersController');
const WebinarController = require('./objects/webinarController');
// const TrainingsController = require('./objects/trainingsController');
import TrainingsController from './objects/trainingsController';

// Component Controllers
const VideoController = require('./components/videoController');
const ShareController = require('./components/shareController');

// Libraries & Helper controllers
const GoogleAnalytics = require('./helpers/googleAnalytics');

const ViewHandler = {

    selectors: {
        select2: '.select2-js'
    },

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.onDocumentReady();
        });
    },

    onDocumentReady() {
        // Controllers
        HomeController.init();
        ItemController.init();
        JournalController.init();
        FiltersController.init();
        TrainingsController.init();
        WebinarController.init();
        // Components
        VideoController.init();
        ShareController.init();
        // Libraries
        GoogleAnalytics.init();
        this.initParticles();
        // Listeners
        this.setListeners();
    },

    setListeners() {

    },

    initParticles() {
        particlesJS.load('particles-js', '../particles.json');
    }
};

export default ViewHandler;
// module.exports = ViewHandler;
