
const GoogleAnalytics = {
    selectors: {
        slider: '.ga-slide-js',
        resources: '#filter_resources_send',
        topics: '#filter_topic .topic--item',
        newsletter: '#newsletter-subscibed-js',
        categories: '#filter_categories_send',
        highlighted: '.ga-highlighted-js',
        webinar: '.opener-webinar-js',
        journalRead: '.ga-journal-read-js',
        journalDownload: '.ga-journal-download-js',
        journalVideo: '.ga-journal-video-js',
        articlesRead: '.ga-articles-read-js',
        articlesVideo: '.ga-articles-video-js',
        movementsPro: '.ga-pro-1',
        movementsNopro: '.ga-pro-0'
    },

    init() {
        this.setListeners();
    },

    setListeners() {
        $(this.selectors.slider).on('click', ev => this.gaSliders(ev.currentTarget));
        $(this.selectors.resources).on('click', () => this.gaResources());
        $(this.selectors.topics).on('click', ev => this.gaTopics(ev.currentTarget));
        $(this.selectors.newsletter).on('click', () => this.gaNewsletters());
        $(this.selectors.categories).on('click', () => this.gaCategories());
        $(this.selectors.highlighted).on('click', ev => this.gaHighlighted(ev.currentTarget));
        $(this.selectors.webinar).on('click', ev => this.gaWebinar(ev.currentTarget));
        $(this.selectors.journalRead).on('click', () => this.gaJournalRead());
        $(this.selectors.journalDownload).on('click', () => this.gaJournalDownload());
        $(this.selectors.journalVideo).on('click', () => this.gaJournalVideo());
        $(this.selectors.articlesRead).on('click', ev => this.gaArticlesRead(ev.currentTarget));
        $(this.selectors.articlesVideo).on('click', () => this.gaArticlesVideo());
        $(this.selectors.movementsPro).on('click', () => this.gaMovementsPro());
        $(this.selectors.movementsNoPro).on('click', () => this.gaMovementsNoPro());
    },

    gaSliders(el) {
        const title = $(el).closest('.slider-content').data('title');
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Home', 'clic', title);
    },

    gaResources() {
        ga('create', 'UA-91739718-1');
        ga('event', 'clic', {'event_label': 'Recursos'});
    },

    gaTopics(el) {
        const title = $(el).val();
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'topics', 'clic', title);
    },

    gaNewsletters() {
        ga('create', 'UA-91739718-1');
        ga('event', 'clic', {'event_label': 'Newsletter'});
    },

    gaCategories() {
        ga('create', 'UA-91739718-1');
        ga('event', 'clic', {'event_label': 'Categorías'});
    },

    gaHighlighted(el) {
        ga('create', 'UA-91739718-1');
        const title = $(el).data('title');
        ga('event', 'Acceder', {'event_label': title});
    },

    gaWebinar(el) {
        ga('create', 'UA-91739718-1');
        const title = $(el).data('title');
        ga('send', 'event', 'NeuroTV', 'Play', title);
    },

    gaJournalRead() {
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Revistas', 'clic', 'LeerRevista');
    },

    gaJournalDownload() {
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Revistas', 'clic', 'DescargarRevista');
    },

    gaJournalVideo() {
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Revistas', 'clic', 'PlayRevista');
    },

    gaArticlesRead(el) {
        ga('create', 'UA-91739718-1');
        const title = $(el).data('title');
        ga('send', 'event', 'Artículos', 'clic', title);
    },

    gaArticlesVideo() {
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Artículos', 'clic', 'playvideo');
    },

    gaMovementsPro() {
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Rethinkingprofesional', 'clic', 'playvideo');
    },

    gaMovementsNoPro() {
        ga('create', 'UA-91739718-1');
        ga('send', 'event', 'Rethinkingparticular', 'clic', 'playvideo');
    }
  };

  module.exports = GoogleAnalytics;
