
const VideoController = {
    selectors: {
        popupVideo: '#popupVideo',
        popupChat: '#popupChat',
        closeChat: '#closeChat',
        openerVideo: '.openerVideo, .open-video-js',
        openerOnline: '.openerOnline',
        openerVideoBtn: '.openerVideoBtn',
        popupVideoClose: '#popupVideo .btnClose'
    },

    init() {
        this.setListeners();
        this.moveModalToBody();
    },

    setListeners() {
        $(this.selectors.popupVideoClose).on('click', ev => this.closeVideoModal());
        $(this.selectors.timeVideoBtn).on('click', ev => this.openVideoModal(ev));
        $(this.selectors.openerVideo).on('click', ev => this.openVideoModal(ev));
        $(this.selectors.openerOnline).on('click', ev => this.openIframeModal(ev));
        $(this.selectors.closeChat).on('click', () => this.closeChat());
    },

    openVideoModal(ev) {
        let video = $(ev.currentTarget).data('url');
        $(this.selectors.popupVideo + ' iframe').attr('src', video);
        $(this.selectors.popupVideo).addClass('active');
    },

    openIframeModal(ev) {
        let online = $(ev.currentTarget).data('online');
        let chat = $(ev.currentTarget).data('chat');

        $(this.selectors.popupVideo + ' iframe').replaceWith(online);
        $(this.selectors.popupVideo).addClass('active');

        if (chat) {
            $(this.selectors.popupChat).empty().append(chat);
            if (!$(".open-chat-js")[0]) {
                $(this.selectors.popupVideo).append('<div class="open-chat-js"><i class="fa fa-3x fa-comments-o"></i></div>');
                $('.open-chat-js').on('click', () => this.openChat());
            }
        }
    },

    closeVideoModal() {
        $(this.selectors.popupVideo + ' iframe').attr('src', '');
        $(this.selectors.popupVideo).removeClass('active');
    },

    moveModalToBody() {
        $(this.selectors.popupVideo).appendTo('body');
    },

    openChat() {
        $(this.selectors.popupChat).removeClass('d-none');
        $(this.selectors.closeChat).removeClass('d-none');
    },

    closeChat() {
        $(this.selectors.popupChat).addClass('d-none');
        $(this.selectors.closeChat).addClass('d-none');
    }
};

module.exports = VideoController;
