
const ShareController = {
    selectors: {
        shareIcon: '.share-container > .share',
        shareContainer: '.share-container__media',
        shareBtn: '.share-media-js'
    },

    init() {
        this.setListeners();
        $(this.selectors.shareContainer).hide();
    },

    setListeners() {
        $(this.selectors.shareIcon).on('click', ev => this.openShareMenu(ev));
        $(this.selectors.shareContainer).on('mouseleave', ev => this.closeShareMenu(ev));
        $(this.selectors.shareBtn).on('click', ev => this.shareToSocialMedia(ev));
    },

    openShareMenu(ev) {
        $(ev.currentTarget).hide().next().show('fast');
    },

    closeShareMenu(ev) {
        $(ev.currentTarget).hide().prev().show('fast');
    },

    getShareData(elem) {
        return {
            social: $(elem).data('social'), 
            title: $(elem).data('title'),
            content: $(elem).data('content')
        };
    },

    shareToSocialMedia(ev) {
        let data = this.getShareData(ev.currentTarget);

        console.log(data);
        
        switch (data.social) {
            case 'linkedin':
                
                break;
            case 'facebook':
                
                break;
            case 'twitter':
                
                break;
            case 'email':
                
                break;
        }
    }
};

module.exports = ShareController;
